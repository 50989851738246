import { registerBlockType } from '@wordpress/blocks';
import { Dashicon } from '@wordpress/components';
import './style.css';

const { TextControl, Button } = wp.components;
const { withSelect } = wp.data;

const AddToCartQuants = ({ attributes, setAttributes, productId }) => {
    const { quantity = 1 } = attributes;

    const handleQuantityChange = (newQuantity) => {
        setAttributes({ quantity: newQuantity });
    };

    const handleIncrement = () => {
        setAttributes({ quantity: quantity + 1 });
    };

    const handleDecrement = () => {
        if (quantity > 1) {
            setAttributes({ quantity: quantity - 1 });
        }
    };

    const handleAddToCart = () => {
        jQuery(document).ready(function($) {
            const block = document.querySelector(`[data-product-id="${productId}"]`);
            const quantityInput = block.querySelector('.quantity-input');
            const quantity = parseInt(quantityInput.value, 10);

            // Perform AJAX request to add to cart
            $.post(
                addcartquants_vars.ajaxurl,
                {
                    action: 'add_to_cart',
                    product_id: productId,
                    quantity: quantity,
                },
                function(response) {
                    if (response.success) {
                        // Update the page to show WooCommerce notices
                        if (response.data && response.data.message) {
                            // Display the WooCommerce notice
                            $('.woocommerce-notices-wrapper').html(response.data.message).show();
                            $('body').trigger('added_to_cart', [response.fragments, response.cart_hash, productID]);
                            if (response.fragments) {
                                $.each(response.fragments, function(key, value) {
                                  $(key).replaceWith(value);
                                });
                              }
                        }
                    } else {
                        alert('Failed to add item to cart');
                    }
                }
            );
        });
    };

    return (
        <div>
            <div>
                <Button onClick={handleDecrement}>-</Button>
                <TextControl
                    label="Quantity"
                    type="number"
                    value={quantity}
                    onChange={(value) => handleQuantityChange(value)}
                    className="quantity-input"
                />
                <Button onClick={handleIncrement}>+</Button>
            </div>
            <Button isPrimary onClick={handleAddToCart}>
                <Dashicon icon="cart" />
                Add to Basket
            </Button>
            <div>Product ID: {productId}</div>{' '}
            {/* Display Product ID for testing */}
        </div>
    );
};

const AddToCartQuantsWithData = withSelect((select, ownProps) => {
    const { getBlockParentsByBlockName } = select('core/block-editor');
    const clientId = ownProps.clientId;

    // Try to get product ID from parent block
    const parentProductBlockClientId = getBlockParentsByBlockName(
        clientId,
        'woocommerce/product'
    )[0];
    if (parentProductBlockClientId) {
        const parentBlockAttributes = select(
            'core/block-editor'
        ).getBlockAttributes(parentProductBlockClientId);
        return {
            productId: parentBlockAttributes.productId,
        };
    }

    // Fallback if not nested in a product block
    return { productId: null };
})(AddToCartQuants);

registerBlockType('custom-add-cart-quants/my-custom-block', {
    title: 'Add to Cart with Quantity',
    icon: 'cart',
    category: 'theme',
    attributes: {
        product_id: {
            type: 'string',
            default: '',
        },
        quantity: {
            type: 'number',
            default: 1,
        },
    },
    edit: AddToCartQuantsWithData,
    save: ({ attributes }) => {
        const { product_id, quantity } = attributes;
        return (
            <div data-product-id={product_id} className="my-custom-block">
                <div className="custom-block-container">
                    <button className="decrement">-</button>
                    <input
                        type="number"
                        className="quantity-input"
                        value={quantity}
                    />
                    <button className="increment">+</button>
                </div>
                <button className="add-to-cart">
                    <span id="cart-icon-cart"></span> Add to Basket
                </button>
            </div>
        );
    }
});
